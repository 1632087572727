export const UPDATE_LOGIN = 'UPDATE_LOGIN';
export const UPDATE_USER_PERMISSIONS = 'UPDATE_USER_PERMISSIONS';
export const UPDATE_USER = 'UPDATE_USER';
export const SET_ROLE = 'SET_ROLE';
export const GET_LISTINGS = 'GET_LISTINGS';
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const ORG_MODAL = 'ORG_MODAL';

export const updateLoginStatus = isLoggedIn => {
    return {
        type: UPDATE_LOGIN,
        isLoggedIn
    }
}

export const updateUserPermissions = perms => {
    return {
        type: UPDATE_USER_PERMISSIONS,
        perms
    }
}

export const updateUser = user => {
    return {
        type: UPDATE_USER,
        user
    }
}

export const setRole = role => {
    return {
        type: SET_ROLE,
        role
    }
}

export const updateOrganization = org => {
    return {
        type: UPDATE_ORGANIZATION,
        org
    }
}

export const openOrganizationModal = () =>{
    return {
        type: ORG_MODAL,
        show: true
    }
}

export const closeOrganizationModal = () =>{
    return {
        type: ORG_MODAL,
        show: false
    }
}

export default updateLoginStatus;
