import React from 'react';
import { Auth, API } from 'aws-amplify';
// import {Form, FormGroup, Col, ControlLabel, FormControl, Checkbox, Button  } from 'react-bootstrap';
import Form from 'react-bootstrap/lib/Form';
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Col from 'react-bootstrap/lib/Col';
import FormControl from 'react-bootstrap/lib/FormControl';
import Button from 'react-bootstrap/lib/Button';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';
import { updateLoginStatus } from '../js/actions';
import { connect } from 'react-redux';
import { GTM_DATA_LAYER } from '../js/gtm';

class NewLogin extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isChangingPassword: false,
            errors: {password: '', repeatPassword: '', checkPassword: ''},
            password:'',
            confirmPassword: '',
            isValidPassword: true,
            isValidPasswordEquality: true,
            isTokeIdAvailable: location.search.indexOf('token_id=') > -1
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.isValid = this.isValid.bind(this);
        this.validateField = this.validateField.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.state.isTokeIdAvailable) {
            this.changePassword();
        }        
    }

    async changePassword() {
        try {
            GTM_DATA_LAYER.push({
                'event': 'changePassword',
                'eventType': 'New User Password Update'
            });
            this.setState({isChangingPassword: true});
            await Auth.completeNewPassword(this.props.user, this.state.password, {})
            this.props.onPasswordUpdate(this.state.confirmPassword);
            this.setState({
                errors: {...this.state.errors, checkPassword: ''}
            });
        } catch(e) {
            this.setState({
                errors: {...this.state.errors, checkPassword: e.message}
            });
        }
        this.setState({isChangingPassword: false});
    }

    validateField(name, value) {
        let validationErrors = this.state.errors;
        let passwordValid = this.state.isValidPassword;
        let repeatPasswordValid = this.state.isValidPasswordEquality;
        switch (name) {
            case 'password':
                let regExp = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,16}$/;
                passwordValid = regExp.test(value);
                validationErrors.password = passwordValid ? '' : 'Password must contain atleast one lowercase character, one uppercase character, \
                one numeric character and one special character. Password must be atleast 8 characters \
                long.';
                break;
            case 'confirmPassword':
                repeatPasswordValid = this.isValid();
                validationErrors.repeatPassword = repeatPasswordValid ? '' : 'Passwords don\'t match';
                break;
        }

        this.setState({
            errors: validationErrors,
            isValidPassword: passwordValid,
            isValidPasswordEquality: repeatPasswordValid
        });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.validateField(name, value));
    }

    isValid() {
        const { password, confirmPassword } = this.state;
        if(password && password==confirmPassword) {
            return true;
        }
        return false;
    }

    render() {
        return (
            <Grid fluid>
                <Row className="page-container form-wrap">
                    <Col xs={12}>
                        <h2 className="modal-heading">Enter a New Password</h2>
                        {!this.state.isTokeIdAvailable ? <h4>You're almost there! Unfortunately the registration link is incomplete. Please be sure to use the entire link provided in the e-mail you received.</h4> : ''}
                    </Col>
                    <Col xs={12}>
                        <Form horizontal onSubmit={this.handleSubmit}>
                            <FormGroup 
                                controlId="newPassword"
                            >
                                <Col xs={12}>
                                    <FormControl 
                                        type="password" 
                                        name="password" 
                                        value={this.state.password} 
                                        onChange={this.handleChange} 
                                        placeholder="New Password"
                                        disabled={!this.state.isTokeIdAvailable}
                                    />
                                </Col>
                            </FormGroup>
                            <FormControl.Feedback />
                            {this.state.errors.password.length > 0 && 
                            <HelpBlock className="error-text">{this.state.errors.password}</HelpBlock>}
                            <FormGroup controlId="confirmNewPassword">
                                <Col xs={12}>
                                    <FormControl 
                                        type="password" 
                                        name="confirmPassword" 
                                        value={this.state.confirmPassword} 
                                        onChange={this.handleChange} 
                                        placeholder="Confirm New Password"
                                        disabled={!this.state.isTokeIdAvailable}
                                    />
                                </Col>
                            </FormGroup>
                            {this.state.errors.repeatPassword.length > 0 && 
                            <HelpBlock className="error-text">{this.state.errors.repeatPassword}</HelpBlock>}
                            <FormGroup>
                                <Col xs={12}>
                                    <Button className={this.isValid() ? "login-btn cursor-pointer" : 'default-btn'} disabled={!this.state.isTokeIdAvailable || !this.isValid() || this.state.isChangingPassword || this.props.processing} type="submit">
                                    Change Password <span className={'glyphicon glyphicon-refresh'+(this.state.isChangingPassword || this.props.processing ? ' spinning':' hide')}></span>
                                    </Button>
                                    <FormControl.Feedback />
                                    {this.state.errors.checkPassword.length > 0 &&
                                    <HelpBlock className={'text-center '+(this.state.errors.checkPassword ? 'error-text':'hide')}>{this.state.errors.checkPassword}</HelpBlock>}
                                </Col>
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateLoginStatus: (isLoggedIn) => {
            dispatch(updateLoginStatus(isLoggedIn));
        }
    }
};

export default connect(
    null,
    mapDispatchToProps
)(NewLogin);
