import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Header from './header'
import Footer from './footer'
import '../styles/bundle.css';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
            keywords
            API_CONFIG {
              COGNITO {
                Region
                IdentityPoolId
                UserPoolId
                ClientId
                EndPoint {
                  Url
                }
                Account {
                  Url
                }
                TProEndpoint {
                  Url
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: data.site.siteMetadata.description },
            { name: 'keywords', content: data.site.siteMetadata.keywords },
          ]}
          link={[
            {rel: 'icon', type: 'image/png', href: 'https://www.redawning.com/sites/default/files/redawning/images/favicon-196x196.png', sizes: '196x196'},
            {rel: 'icon', type: 'image/png', href: 'https://www.redawning.com/sites/default/files/redawning/images/favicon-96x96.png', sizes: '96x96'},
            {rel: 'icon', type: 'image/png', href: 'https://www.redawning.com/sites/default/files/redawning/images/favicon-32x32.png', sizes: '32x32'},
            {rel: 'icon', type: 'image/png', href: 'https://www.redawning.com/sites/default/files/redawning/images/favicon-16x16.png', sizes: '16x16'},
            {rel: 'icon', type: 'image/png', href: 'https://www.redawning.com/sites/default/files/redawning/images/favicon-128.png', sizes: '128x128'}
          ]}
        >
          <html lang="en" />
        </Helmet>
        <Header siteTitle={data.site.siteMetadata.title} APIConfig={data.site.siteMetadata.API_CONFIG} />
        <div>
          {children}
        </div>
        <Footer/>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout