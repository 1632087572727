import React, {Component} from 'react'
import { Grid, Col, Row, Button} from 'react-bootstrap';

class Footer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            cookieBannerDismissed: false
        }
    }

    componentDidMount() {
        this.setState({ 
            cookieBannerDismissed: (localStorage.getItem('cookieBannerDismissed')) ? true : false
        });

        this.currentYear = new Date().getFullYear();
        
        this.hideCookieBanner = () => {
            localStorage.setItem('cookieBannerDismissed', true);
            this.setState({ 
                cookieBannerDismissed: true
            });
        }
    }

    render() {
        return (
            <div>
                <Grid className="footer pull-left">
                    <Row>
                        <Col md={4} sm={12} className="text-center p-0">
                            <a className="opacity-one">© {this.currentYear} RedAwning.com Inc., RedAwning Group, or its affiliates. All rights reserved.</a>
                        </Col>
                        <Col md={4} sm={12} className="p-0 text-center">
                                <a href="/terms">Terms and Conditions</a>
                                <a href="/privacy">Privacy Policy</a>
                                <a href="/faq">FAQs</a>
                        </Col>
                        <Col md={4} sm={12} className=" text-center p-0">
                                <a className="opacity-one footer-right">
                                    Powered by RedAwning © {this.currentYear}
                                    <img src="/uploads/RedAwning logo.jpg" />
                                </a>
                        </Col>
                    </Row>
                </Grid>

                <Grid className="pull-left">
                    {!this.state.cookieBannerDismissed ? <Col sm={12} className="cookie-banner">
                        <h4>This site uses cookies to ensure you get the best experience possible.  By using our website, you agree to our <a className="opacity-one" href="/privacy" target="_blank">Privacy Policy</a>.</h4>
                        <Button className="btn btn-primary banner-close" onClick={this.hideCookieBanner}>X</Button>
                    </Col> : ''}
                </Grid>
            </div>
        );
    }
}

export default Footer;
