import React, {Component} from 'react'
import Link from 'gatsby-link'
import {
    Navbar, Nav, NavItem, Grid,
    Col, Row, Modal, Button, Form,
    FormGroup, ControlLabel, FormControl, HelpBlock
} from 'react-bootstrap';
import Amplify, { API, Auth } from 'aws-amplify';
import $ from 'jquery';
import NewLogin from './NewLogin';
import { Provider } from "react-redux";
import configureMockStore from "redux-mock-store";
import { USER_TYPES } from '../js/constants';
import { GTM_DATA_LAYER } from '../js/gtm';

const mockStore = configureMockStore();
const store = mockStore({});
const windowGlobal = typeof window !== 'undefined' && window;

class Header extends Component {

    constructor(props) {
        super(props);
        let {Region, IdentityPoolId, UserPoolId, ClientId, EndPoint, Account, TProEndpoint} = props.APIConfig.COGNITO
        Amplify.configure({
            Auth: {
                identityPoolId: IdentityPoolId, //REQUIRED - Amazon Cognito Identity Pool ID
                region: Region, // REQUIRED - Amazon Cognito Region
                userPoolId: UserPoolId, //OPTIONAL - Amazon Cognito User Pool ID
                userPoolWebClientId: ClientId, //OPTIONAL - Amazon Cognito Web Client ID
            },
            API: {
                endpoints: [
                    {
                        name: "rapapi",
                        endpoint: EndPoint.Url,
                        region: Region
                    },
                    {
                        name: "accapi",
                        endpoint: Account.Url,
                        region: Region
                    },
                    {
                        name: "tproapi",
                        endpoint: TProEndpoint.Url,
                        region: Region
                    },
                ]
            }
        });
        this.state = {
            showLoginModal: false,
            showSignUpModal: false,
            showSignUpThankYouModal: false,
            showChangeCognitoPasswordModal: false,
            agencyModal: false,
            activeKey: 1,
            email: "",
            accreditationClia: "",
            accreditationIata: "",
            accreditationTpr: "",
            password: "",
            newInputPassword: '',
            isSigningIn: false,
            isRequestingAccount: false,
            errors: {email: '', password: '', login: '', forgotPassword: '', newPassword: ''},
            errorCode: undefined,
            tokenId: null,
            store: null,
            isEmailTouched: false,
            isRequestEmailTouched: false,
            isPasswordTouched: false,
            isIATATouched: false,
            isTPRTouched: false,
            isCLIATouched: false,
            isValidPassword: false,
            isValidEmail: false,
            isValidNewPassword: false,
            forgotPassword: false,
            newPassword: false,
            code: '',
            hasRegistered: false
        };


        this.login = this.login.bind(this);
        this.userRegistration = this.userRegistration.bind(this);
        this.passwordUpdated = this.passwordUpdated.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.validateField = this.validateField.bind(this);
        this.handleForgotPassword = this.handleForgotPassword.bind(this);
        this.submitNewPassword = this.submitNewPassword.bind(this);
        this.handleSubmitPassword = this.handleSubmitPassword.bind(this);
        this.showForgotPassword = this.showForgotPassword.bind(this);

        this.showLogin = () => {
            this.setState({showLoginModal: !this.state.showLoginModal});
            this.setState({showChangeCognitoPasswordModal: false});
        };

        this.showSignUp = () => {
            GTM_DATA_LAYER.push({
                'event': 'register',
                'eventType': 'showRegisterForm'
            });
            this.setState({showSignUpModal: !this.state.showSignUpModal, agencyModal: false});
        };
        
        this.showSignUpThankYou = () => {
            GTM_DATA_LAYER.push({
                'event': 'register',
                'eventType': 'showRegisterThankYou'
            });
            this.setState({showSignUpThankYouModal: !this.state.showSignUpThankYouModal});
            this.setState({showSignUpModal: false});
        };
        
        this.showChangeCognitoPassword = () => {
            GTM_DATA_LAYER.push({
                'event': 'changePassword',
                'eventType': 'showChangeCognitoPassword'
            });
            this.setState({showLoginModal: false});
            this.setState({showChangeCognitoPasswordModal: !this.state.showChangeCognitoPasswordModal});
        }

        this.scrollToBottom = (location, e) => {
            this.setState({ activeKey : e });
            $('html,body').animate({
            scrollTop: $(location).offset().top },
            'fast');
            if (location === '#home') {
                $('.home-link').addClass('active-home-link');
            } else {
                $('.home-link').removeClass('active-home-link');
            }
        };

        this.resetFormState = () => {
            this.setState({
                email: '', 
                password: '', 
                accreditationClia: '', 
                accreditationIata: '', 
                accreditationTpr: '', 
                errors: {email: '', password: '', login: '', forgotPassword: '', newPassword: ''},
                errorCode: undefined
            });
        };
    }

    componentDidMount(){
        Auth.currentAuthenticatedUser()
        .then(user => {
            if ((window.location.pathname.indexOf('covid') !== -1 || window.location.pathname.indexOf('faq') !== -1 || window.location.pathname.indexOf('privacy') !== -1 || window.location.pathname.indexOf('terms') !== -1)) {
                // nothing to do
            } else {
                window.location = window.location.origin + "/#/search";
            }
        })
        .catch(e => {
            let tokenId = this.getParameterByName('token_id')
            if(tokenId) {
                this.setState({
                    tokenId
                });
                this.showLogin();
            }

            let register = this.getParameterByName('register')
            if(register) {
                this.showSignUp();
            }
        
            const signUpButton = document.querySelector('.sign-up');
            if (signUpButton) {
                signUpButton.addEventListener('click', event => {
                    this.showSignUp();
                });
            } 
        });
    }

    getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    async login(confirmPassword) {
        var vm=this;
        let user;
        vm.setState({
            isSigningIn: true,
            errors: {...this.state.errors, login: undefined},
            errorCode: undefined
        });
        try {
            let email = this.state.email.toLowerCase();
            let password = confirmPassword || this.state.password;
            user=await Auth.signIn(email, password);
            if(user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                GTM_DATA_LAYER.push({
                    'event': 'logIn',
                    'eventType': 'newPasswordRequired'
                });
                this.setState({
                    user
                });
                this.showChangeCognitoPassword();
            } else {
                try {
                    let currentUser = await API.get("accapi","/currentuser",{});
                    // window.localStorage.setItem('currentUser',JSON.stringify(currentUser));
                    if(currentUser){
                        GTM_DATA_LAYER.push({
                            'event': 'logIn',
                            'eventType': 'getCurrentUser'
                        });
                        let tproOrgs = currentUser['organization_roles'].filter(org=>{
                            return Object.keys(USER_TYPES).filter(type=>{
                                if(org.roles.indexOf(USER_TYPES[type])>-1)
                                    return true
                            }).length
                        })
                        if(!tproOrgs.length) {
                            GTM_DATA_LAYER.push({
                                'event': 'logIn',
                                'eventType': 'notTproSignOut'
                            });
                            await Auth.signOut();
                            let error = {
                                name: 'Not Authorized',
                                message: 'This user is not authorized with RedAwning TravelPro',
                                code: 'UserNotFoundException'
                            }
                            this.failed(error)
                            throw error
                        }
                        window.location = window.location.origin + "/#/search";
                    } else {
                        if (this.state.tokenId && password && !this.state.hasRegistered) {
                            this.userRegistration(password)
                        }
                    }
                } catch(e) {
                    if (this.state.tokenId && password && !this.state.hasRegistered) {
                        vm.setState({
                            isSigningIn: true,
                        });
                        this.userRegistration(password);
                    } else {
                        vm.setState({
                            errors: {...this.state.errors, login: e.message}
                        });  
                    }
                              
                }                
            }
        } catch(err) {
            this.failed(err);
            vm.setState({
                errors: {...this.state.errors, login: err.message}
            }); 
        }
        vm.setState({isSigningIn: false});
    }

    async userRegistration(confirmPassword) {
        if(this.state.tokenId && confirmPassword) {
            try {
                //console.log('try post to tpuserregistration using tokenId', tokenId);
                let regTokenPath = `/registration/${this.state.tokenId}`;
                this.setState({
                    isSigningIn: true,
                    hasRegistered: true
                });
                GTM_DATA_LAYER.push({
                    'event': 'changePassword',
                    'eventType': 'registerUser'
                });
                await API.post("tproapi",regTokenPath, {
                    body: {}
                });
                // set password
                this.login(confirmPassword)
            } catch(e) {
                this.setState({
                    isSigningIn: false
                });
                this.failed({message: 'Invalid TokenID'})
            }
        } else {
            this.failed({message:'Please follow the link sent in your email to finish the registration process'})
        }
    }

    async handleForgotPassword(e) {
        e.preventDefault();
        this.setState({
            errors: {email: '', password: '', login: '', forgotPassword: '', newPassword: '' }
        });

        let sent;
        try {
            sent = await Auth.forgotPassword(this.state.email);
        } catch(err) {
            this.setState({
                errors: {...this.state.errors, forgotPassword: err.message},
                errorCode: err.code
            });
        }
        if(sent) {
            this.setState({
                newPassword: true,
                password: '',
                newInputPassword: ''
            })
        }
    }

    async submitNewPassword() {
        GTM_DATA_LAYER.push({
            'event': 'changePassword',
            'eventType': 'submitNewPassword'
        });
        try {
            await Auth.forgotPasswordSubmit(this.state.email,this.state.code,this.state.newInputPassword);
            this.showLogin();
        } catch(err) {
            this.setState({
                errors: {...this.state.errors, newPassword: err.message}
            });
        }
    }

    handleSubmitPassword(e) {
        e.preventDefault();
        this.setState({
            errors: {email: '', password: '', login: '', forgotPassword: '', newPassword: '' }
        });
        this.submitNewPassword();
    }

    showForgotPassword() {
        GTM_DATA_LAYER.push({
            'event': 'changePassword',
            'eventType': 'showForgotPassword'
        });
        this.setState({
            forgotPassword: true,
            newPassword: false
        });

        this.showLogin();
    }

    passwordUpdated(confirmPassword) {
        if (this.state.tokenId && this.state.email && confirmPassword) {
            try {
                GTM_DATA_LAYER.push({
                    'event': 'changePassword',
                    'eventType': 'passwordUpdated'
                });
                this.userRegistration(confirmPassword)
            } catch (e) {
                //console.log(`"Error, did not register tokenId ${this.state.tokenId}"`, e.message);
            }
        } else {
            this.setState({
                user: null,
                isSigningIn: false,
                password: '',
                showChangeCognitoPasswordModal: false
            });
        }        
    }

    async userRequestAccount() {
        GTM_DATA_LAYER.push({
            'event': 'register',
            'eventType': 'userRequestAccount'
        });
        this.setState({isSigningIn: true});
        let payload = {
            body: {}
        };
        if ((this.state.email && (this.state.accreditationIata || this.state.accreditationClia || this.state.accreditationTpr))) {
            payload.body.email = this.state.email;
            payload.body.accreditation_iata = this.state.accreditationIata;
            payload.body.accreditation_clia = this.state.accreditationClia;
            payload.body.accreditation_tpr = this.state.accreditationTpr;
        
            try {
                await API.post("tproapi","/viewerusers", payload);
                this.showSignUpThankYou();
            } catch(e) {
                //console.log('userRequestAccount Error:', e);
                this.failed({message: 'Invalid request'})
            }
        }
        this.setState({isSigningIn: false});
    }

    failed(err) {
        this.setState({
            isSigningIn: false,
            isRequestingAccount: false,
            errors: {...this.state.errors, login: err.message},
            errorCode: err.code
        });
    }

    validateField(name, value) {
        let validationErrors = this.state.errors;
        let passwordValid = this.state.isValidPassword;
        let emailValid = this.state.isValidEmail;
        let newPasswordValid = this.state.isValidNewPassword;
        let regExp = '';
        validationErrors.login = '';
        switch (name) {
            case 'email':
                regExp = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
                emailValid = regExp.test(value);
                validationErrors.email = emailValid ? '' : 'Invalid email. Please input a valid email address.';
                break;
            case 'password':
                passwordValid = (value !== '');
                break;
            case 'newInputPassword':
                regExp = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,16}$/;
                newPasswordValid = regExp.test(value);
                validationErrors.newPassword = newPasswordValid ? '' : 'Password must contain atleast one lowercase character, one uppercase character, \
                one numeric character and one special character. Password must be atleast 8 characters \
                long.';
        }

        validationErrors.forgotPassword = '';
        validationErrors.login = '';

        this.setState({
            errors: validationErrors,
            isValidEmail: emailValid,
            isValidPassword: passwordValid,
            isValidNewPassword: newPasswordValid
        });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.validateField(name, value));
    }

    clickLogin() {
        this.login();
    }

    clickRequestAccount() {
        this.userRequestAccount();
    }

    getValidationState(fieldName) {
        switch(fieldName) {
            case 'email':
            case 'password':
                if (this.state.errors[fieldName].length === 0) {
                    return null;
                } else {
                    return 'error';
                }
            default:
                if (this.state[fieldName] !== '') {
                    return null;
                } else {
                    return 'error';
                }
        }
    }

    requestAgencyAccount() {
        this.setState({
            agencyModal: true
        })
    }

    render() {
        return (
        <div className="main-header" id="home">
            <div>
                <Navbar fluid fixedTop collapseOnSelect>
                    <Navbar.Header>
                        <Navbar.Brand>
                            <Link className="no-margin" id="brand" to="/home">
                                <img src="/app/assets/icons/redawning_travelpro_logo.svg" />
                           </Link>
                        </Navbar.Brand>
                        <Navbar.Toggle />
                    </Navbar.Header>
                    <Navbar.Collapse>
                        <Nav pullRight activeKey={this.state.activeKey}>
                            {(windowGlobal && 
                                windowGlobal.location && 
                                !(window.location.pathname.indexOf('covid') !== -1 || windowGlobal.location.pathname.indexOf('faq') !== -1 || 
                                    windowGlobal.location.pathname.indexOf('privacy') !== -1 || 
                                    windowGlobal.location.pathname.indexOf('terms') !== -1)) ? <NavItem className="text-16" href="#jump2" eventKey={2} onClick={() => this.scrollToBottom('#jump2', 2)}>{window && window.document && window.document.getElementsByClassName('jump2-title') && window.document.getElementsByClassName('jump2-title')[0] && window.document.getElementsByClassName('jump2-title')[0].textContent}</NavItem>:null}
                            {(windowGlobal && 
                                windowGlobal.location && 
                                !(window.location.pathname.indexOf('covid') !== -1 || windowGlobal.location.pathname.indexOf('faq') !== -1 || 
                                    windowGlobal.location.pathname.indexOf('privacy') !== -1 || 
                                    windowGlobal.location.pathname.indexOf('terms') !== -1)) ? <NavItem className="text-16" href="#jump3" eventKey={3} onClick={() => this.scrollToBottom('#jump3', 3)}>{window && window.document && window.document.getElementsByClassName('jump3-title') && window.document.getElementsByClassName('jump3-title')[0] && window.document.getElementsByClassName('jump3-title')[0].textContent}</NavItem>:null}
                            {(windowGlobal && 
                                windowGlobal.location && 
                                !(window.location.pathname.indexOf('covid') !== -1 || windowGlobal.location.pathname.indexOf('faq') !== -1 || 
                                    windowGlobal.location.pathname.indexOf('privacy') !== -1 || 
                                    windowGlobal.location.pathname.indexOf('terms') !== -1)) ? <NavItem className="text-16" href="#jump5" eventKey={5} onClick={() => this.scrollToBottom('#jump5', 5)}>{window && window.document && window.document.getElementsByClassName('jump5-title') && window.document.getElementsByClassName('jump5-title')[0] && window.document.getElementsByClassName('jump5-title')[0].textContent}</NavItem>:null}
                            {(windowGlobal && 
                                windowGlobal.location && 
                                (window.location.pathname.indexOf('covid') !== -1 || windowGlobal.location.pathname.indexOf('faq') !== -1 || 
                                    windowGlobal.location.pathname.indexOf('privacy') !== -1 || 
                                    windowGlobal.location.pathname.indexOf('terms') !== -1)) ? <NavItem className="text-16" href="/home">Home</NavItem>:null}
                            {(windowGlobal && 
                                windowGlobal.location) ? <NavItem className="text-16" href="/faq">FAQs</NavItem>:null}
                            {(windowGlobal && 
                                windowGlobal.location && 
                                !(window.location.pathname.indexOf('covid') !== -1 || windowGlobal.location.pathname.indexOf('faq') !== -1 || 
                                    windowGlobal.location.pathname.indexOf('privacy') !== -1 || 
                                    windowGlobal.location.pathname.indexOf('terms') !== -1)) ? <NavItem className="text-16 nav-btn nav-btn-red" onClick={() => { this.showLogin(); this.resetFormState(); }}>Sign In</NavItem>:null}
                            {(windowGlobal && 
                                windowGlobal.location && 
                                !(window.location.pathname.indexOf('covid') !== -1 || windowGlobal.location.pathname.indexOf('faq') !== -1 || 
                                    windowGlobal.location.pathname.indexOf('privacy') !== -1 || 
                                    windowGlobal.location.pathname.indexOf('terms') !== -1)) ? <NavItem className="text-16 nav-btn nav-btn-blue" onClick={() => { this.showSignUp(); this.resetFormState(); }}>Request an Account</NavItem>:null}
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>

            <Modal
                className="sign-up-thank-you-modal"
                show={this.state.showSignUpThankYouModal}
                onHide={() => this.setState({ showSignUpThankYouModal: false })}
            >
                <Modal.Header className="custom-modal-header" closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Grid fluid={true}>
                        <Row>
                            <Col xs={12}>
                                <h2 className="modal-heading">Thank you!</h2>
                                <h3>Your new account is being processed.  Please check your email for further instructions.</h3>
                            </Col>
                        </Row>
                    </Grid>
                </Modal.Body>
            </Modal>

            <Modal
                backdrop={'static'}
                className="sign-up-new-login"
                show={this.state.showChangeCognitoPasswordModal}
            >
                <Modal.Header className="custom-modal-header" hidden>
                    <Modal.Title></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Provider store={store}>
                        <NewLogin user={this.state.user} onPasswordUpdate={this.passwordUpdated} processing={this.state.isSigningIn}/>
                    </Provider>
                </Modal.Body>
            </Modal>

            <Modal
                className="sign-up-modal"
                show={this.state.showSignUpModal}
                onHide={() => this.setState({ showSignUpModal: false })}
            >
                <Modal.Header className="custom-modal-header" closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>

                
                <Modal.Body>
                    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeexW_MgOAnWFgQlHIpKhNMTNy5xyYnbmpYnq7MEBHzUslMkA/viewform" width="100%" height="640" frameborder="0" marginheight="0" marginwidth="0">Loading...</iframe>
                </Modal.Body>

                <Modal.Footer>
                </Modal.Footer>
            </Modal>

            <Modal
                className="log-in-modal"
                show={this.state.showLoginModal}
                onHide={() => this.setState({ showLoginModal: false })}
            >
                <Modal.Header className="custom-modal-header" closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Grid fluid={true}>
                        <Row>
                            <Col xs={12}>
                                <h2 className="modal-heading">Login</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <FormGroup
                                    controlId="loginusernameinput"
                                    validationState={((this.state.isEmailTouched && this.state.errors.email.length > 0) || this.state.errorCode && this.state.errorCode.length) ? "error" : null}
                                >
                                    <ControlLabel>Username</ControlLabel>
                                    <FormControl
                                        type="text"
                                        name="email"
                                        value={this.state.email}
                                        onChange={this.handleChange}
                                        onBlur={() => this.setState({isEmailTouched: true})}
                                        onKeyDown={(e) => e.keyCode === 13 && this.state.isValidEmail && this.state.isValidPassword ? this.clickLogin() : null}
                                        required
                                    />
                                </FormGroup>
                                <FormControl.Feedback />
                                {this.state.errorCode && this.state.errorCode.length && this.state.errors.login.length > 0 && 
                                <HelpBlock className="error-text">{this.state.errors.login}</HelpBlock>}
                                {(this.state.errors.email.length > 0 && this.state.isEmailTouched) && 
                                <HelpBlock className="error-text">{this.state.errors.email}</HelpBlock>}
                            </Col>
                            <Col sm={12}>
                                <FormGroup
                                    controlId="loginpasswordinput"
                                    validationState={this.state.errorCode && this.state.errorCode.length ? "error" : null}
                                >
                                    <ControlLabel>Password</ControlLabel>
                                    <FormControl
                                        type="password"
                                        name="password"
                                        value={this.state.password}
                                        onChange={this.handleChange}
                                        onBlur={() => this.setState({isPasswordTouched: true})}
                                        onKeyDown={(e) => e.keyCode === 13 && this.state.isValidEmail && this.state.isValidPassword ? this.clickLogin() : null}
                                        required
                                    />
                                </FormGroup>
                                <FormControl.Feedback />
                                {this.state.errorCode && this.state.errorCode.length && this.state.errors.login.length > 0 && 
                                <HelpBlock className="error-text">{this.state.errors.login}</HelpBlock>}
                                {(this.state.errors.password.length > 0 && this.state.isPasswordTouched) &&
                                <HelpBlock className="error-text">{this.state.errors.password}</HelpBlock>}
                            </Col>
                            <Col xs={6}>
                                <label className="checkbox-wrap checkbox-input">
                                    <input name="Remember me" type="checkbox" value="" />
                                    <div className="checkbox-label">Remember me</div>
                                </label>
                            </Col>
                            <Col xs={6}>
                                <a href="javascript:void(0)" className="float-right" onClick={this.showForgotPassword}>Forgot Password</a>
                            </Col>
                        </Row>
                        <Row className="button-container">
                            <Col xs={12}>
                                <Button className={!this.state.isValidEmail || !this.state.isValidPassword ? 'default-btn' : "login-btn cursor-pointer"} disabled={!this.state.isValidEmail || !this.state.isValidPassword} onClick={() => this.clickLogin()}>Sign In <i className={'glyphicon glyphicon-refresh ' + (this.state.isSigningIn ? ' spinning':' hide')}></i></Button>
                            </Col>
                        </Row>
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <ul className="footer-list">
                        <a href="/terms">Terms and Conditions</a> &nbsp;&nbsp; <a href="/privacy">Privacy Policy</a>
                    </ul>
                </Modal.Footer>
            </Modal>

            <Modal
                show={this.state.forgotPassword}
                onHide={() => this.setState({ forgotPassword: false, showLogin: true })}
            >
                <Modal.Body>
                    <Grid fluid>
                        <Row>
                            <Col xs={12}>
                                <h2 className="modal-heading">Forgot Password</h2>
                                {this.state.newPassword && <p>You will receive an email with a verification code. Please enter that verification code below to continue.</p>}
                            </Col>
                        </Row>
                        <Row className="page-container form-wrap">
                            <Form onSubmit={this.state.newPassword ? this.handleSubmitPassword : this.handleForgotPassword}>
                                <Col xs={12}>
                                    <FormGroup 
                                        controlId="formHorizontalEmail"
                                        validationState={((this.state.isEmailTouched && this.state.errors.email.length > 0) || this.state.errorCode && this.state.errorCode.length) ? "error" : null}
                                    >
                                        <FormControl 
                                        type="text" 
                                        name="email"
                                        placeholder="Email" 
                                        onBlur={() => this.setState({isEmailTouched: true})}
                                        onChange={this.handleChange}
                                        value={this.state.email} />
                                    </FormGroup>
                                    <FormControl.Feedback />
                                    {this.state.errorCode && this.state.errorCode.length && this.state.errors.forgotPassword.length > 0 && this.state.errors.email === '' && 
                                    <HelpBlock className="error-text">Looks like we're not able to reset your password, please contact customer support for help at: <a href="mailto:travelpro@redawning.com?subject=I need help resetting my password">travelpro@redawning.com</a> or +1 800-946-6509.</HelpBlock>}
                                    {this.state.errors.email.length > 0 && 
                                    <HelpBlock className="error-text">{this.state.errors.email}</HelpBlock>}
                                </Col>
                                {this.state.newPassword && 
                                <Col xs={12}>
                                    <FormGroup controlId="formHorizontalEmail">
                                        <FormControl 
                                            type="text" 
                                            name="code"
                                            placeholder="Verification Code" 
                                            value={this.state.code} 
                                            onChange={this.handleChange} />
                                    </FormGroup>
                                </Col>}
                                {this.state.newPassword && 
                                <Col xs={12}>
                                    <FormGroup 
                                        controlId="formHorizontalPassword"
                                        validationState={(this.state.isPasswordTouched && this.state.errors.newPassword.length > 0) ? "error" : null}
                                    >
                                        <FormControl 
                                            type="password" 
                                            name="newInputPassword"
                                            autoComplete="new-password" 
                                            placeholder="New Password" 
                                            value={this.state.newInputPassword}
                                            onBlur={() => this.setState({isPasswordTouched: true})} 
                                            onChange={this.handleChange} />
                                    </FormGroup>
                                    <FormControl.Feedback />
                                    {this.state.errors.newPassword.length > 0 && 
                                    <HelpBlock className="error-text">{this.state.errors.newPassword}</HelpBlock>}
                                </Col>}
                                <Col xs={12}>
                                    <FormGroup>
                                        <Button type="submit" className={!this.state.isValidEmail  || (this.state.newPassword && !this.state.isValidNewPassword) ? 'default-btn' : "login-btn cursor-pointer"} disabled={(!this.state.isValidEmail || (this.state.newPassword && !this.state.isValidNewPassword))}>
                                        {this.state.newPassword ? 'Save Password' : 'Forgot Password'} <span className={'glyphicon glyphicon-refresh'+ (this.state.isSigningIn ? ' spinning':' hide')}></span>
                                        </Button>

                                        <span className={'text-center ' + (this.state.errorMsg ? 'error-msg':'hide')}>{this.state.errorMsg}</span>
                                    </FormGroup>
                                </Col>
                            </Form>
                        </Row>
                    </Grid>
                </Modal.Body>
            </Modal>
        </div>
        );
    }
}



export default Header